const chunk = (input, size) => {
  let chunked = []
  for (let i = 0; i < input.length; i += size) {
    chunked.push(input.slice(i, i + size))
  }

  return chunked
}

module.exports = { chunk }
