import React from 'react'
import dayjs from 'dayjs'

import banner from '../assets/images/Rectangle 3513.svg'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)
function UpcomingBanner({ program, room }) {
  const BASE_URL = `https://www.hca-oasis.org/`
  return (
    <div className="relative w-9/12 ml-1 mr-3">
      <img
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: "url('"+BASE_URL+program.programImage+"')",
        }}
        className="z-40 rounded-xl"
        alt="images"
        src={banner}
      />
      <div className="absolute top-0 w-full h-full bg-black bg-opacity-60 rounded-xl backdrop-filter backdrop-blur"></div>
      
      <div className="absolute text-white xl:bottom-12 xl:left-16 2xl:bottom-24 2xl:left-24">
      <div
        style={{
          backgroundColor: '#16C787',
          borderColor: '#707070',
          width: 'fit-content',
          borderRadius: 50,
          lineHeight: "1rem",
        }}
        className="border pt-4 pb-3 px-10 mb-3 text-2xl font-avenir font-black -ml-2">Next Event</div>
        <p className="text-4xl font-black mb-3 2xl:text-5xl font-avenir 2xl:py-4">
          {program.programName}
        </p>
        <p className="text-2xl font-black mb-3 2xl:text-4xl font-avenir 2xl:py-2">
          {program.programStartDateTime
            ? dayjs(program.programStartDateTime).format('hh.mm A')
            : null}
        </p>
        <p className="text-2xl 2xl:text-3xl font-avenir 2xl:py-2">{room.name}</p>
      </div>
    </div>
  )
}

export default UpcomingBanner
