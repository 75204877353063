import React from 'react'
// Import Swiper styles
import 'swiper/swiper.scss'

import { Swiper, SwiperSlide } from 'swiper/react'
function Attendees({ userCount, chunkedData }) {
  const BASE_URL = `https://www.hca-oasis.org/`
  return (
    <div className="w-3/12 p-1 bg-white 2xl:p-6 bg-opacity-95 border-hca-grey rounded-xl h-7/8 attendBox ">
      <div className="flex flex-row items-center justify-between h-12 px-4 border-b 2xl:px-12 ">
        <p className="font-black font-avenir ">Attendees</p>
        <p className=" font-avenir">{userCount} Participants</p>
      </div>

      <Swiper
        autoplay={true}
        delay={100}
        loop={true}
        className="flex h-5/6"
        spaceBetween={50}
        slidesPerView={1}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {chunkedData.map((chunk, chunkIndex) => {
          return (
            <SwiperSlide key={`page_${chunkIndex}`} className="flex mt-2 ">
              <div className="flex flex-col w-full h-full ">
                {chunk.map((item) => {
                  return (
                    <div
                      key={`page_${chunkIndex}_subItem_${item.id}`}
                      className="flex flex-row w-full px-4 mx-auto text-left slideContainer 2xl:px-12"
                    >
                      <img
                        alt={`${item.Patient.givenName} ${item.Patient.lastName}`}
                        className="inline-block rounded-full ring-2 ring-white h-14 w-14 2xl:h-24 2xl:w-24"
                        src={`${BASE_URL}assets/${item.avatar}`}
                      />
                      <div className="inline-block my-auto align-middle w-full">
                        <p className="mx-2 ml-6 align-middle text-md 2xl:text-xl font-avenir">
                          {item.Patient.givenName} {item.Patient.lastName}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default Attendees
