// Import Swiper styles
import 'swiper/swiper.scss'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SwiperCore, { Autoplay } from 'swiper'

import Detail from './pages/Detail'
import Home from './pages/Home'

SwiperCore.use([Autoplay])

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/room/:hospiceId/:id" exact>
          <Detail />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
