import React from 'react'
import dayjs from 'dayjs'

import logo_oasis from '../assets/images/Oasis.png'
import logo_hca from '../assets/images/HCA.png'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

function NoEventHeader({ dt }) {
  return (
    <div
      style={{
        width: 'fit-content',
      }}
      className="flex items-center mx-auto mt-5 mb-20"
    >
      <img
        className="object-contain w-24"
        alt="Oasis"
        src={logo_oasis}
      />
      <img
        alt="HCA"
        className="object-contain w-24 ml-4"
        src={logo_hca}
      />

      <div
        style={{
          color: '#2c3445',
        }}
        className="flex flex-col justify-center ml-4 text-2xl text-left 2xl:text-4xl">
        <div>
          <p className="inline-block align-text-bottom font-avenir">
            {dayjs(dt).tz('Asia/Singapore').format('dddd, D MMM YYYY')}
          </p>
          <p className="font-black font-avenir">
            {dayjs(dt).tz('Asia/Singapore').format('HH:mm:ss A')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default NoEventHeader
