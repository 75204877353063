import React from 'react'
import dayjs from 'dayjs'

import banner from '../assets/images/Rectangle 3513.svg'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)
function Banner({ program, room }) {
  const BASE_URL = `https://www.hca-oasis.org/`
  return (
    <div className="relative w-9/12 px-1 mr-2">
      <img
        style={{
          backgroundRepeat: 'no-repeat',
          // backgroundSize: '100% 100%',
          backgroundSize: 'cover',
          backgroundImage: "url('"+BASE_URL+program.programImage+"')",
        }}
        className="z-40 rounded-xl"
        alt="images"
        src={banner}
      />

      {/* <div className="w-full rounded-md h-7/8 gradientImage"></div> */}
      <div className="absolute text-white xl:bottom-12 xl:left-16 2xl:bottom-24 2xl:left-24">
        <p className="text-2xl font-black 2xl:text-5xl font-avenir 2xl:py-4">
          {program.programName}
        </p>
        <p className="text-xl font-black 2xl:text-4xl font-avenir 2xl:py-2">
          {program.programStartDateTime
            ? dayjs(program.programStartDateTime).format('hh.mm A')
            : null}
        </p>
        <p className="text-lg 2xl:text-3xl font-avenir 2xl:py-2">{room.name}</p>
      </div>
    </div>
  )
}

export default Banner
