// Import Swiper styles
import 'swiper/swiper.scss'

import SwiperCore, { Autoplay } from 'swiper'
import { useEffect, useState } from 'react'

import Attendees from '../components/Attendees'
import Banner from '../components/Banner'
import Header from '../components/Header'
import NoEventHeader from '../components/NoEventHeader'
import UpcomingBanner from '../components/UpcomingBanner'
import axios from 'axios'
import { chunk } from '../lib/chunk'
import dayjs from 'dayjs'
import detail from '../mocks/detail.json'
import produce from 'immer'
import { useParams } from 'react-router-dom'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)
SwiperCore.use([Autoplay])
function Detail() {
  const { id, hospiceId } = useParams()
  const [dt, setDt] = useState(new Date().toLocaleString())
  const [data, setData] = useState(detail)

  const [type, setType] = useState('')
  const BASE_URL = `https://www.hca-oasis.org/`

  useEffect(() => {
    const refreshPage = async () => {
      window.location.reload(false)
    }

    const intervalRefreshPage = setInterval(() => {
      refreshPage()
    }, 1000 * 60 * 3) // in miliseconds ( 15 min )
    return () => {
      clearInterval(intervalRefreshPage)
    }
  }, [])

  useEffect(() => {
    let secTimer = setInterval(() => {
      setDt(new Date().toISOString())
    }, 1000)

    return () => clearInterval(secTimer)
  }, [])
  useEffect(() => {
    let fetchData = async () => {
      const result = await axios.get(
        `${BASE_URL}api/tv/room/${hospiceId}/${id}`
      )
      console.log(result)
      if (result.data.data.room.Programs.length !== 0) {
        setType('ongoing')
      } else {
        if (result.data.data.nextEvent) {
          setType('upcoming')
        } else {
          setType('no-event')
        }
      }

      const newObject = produce(result.data.data, (draft) => {
        if (draft.nextEvent) {
          if (draft.nextEvent.Users === undefined) {
            draft.nextEvent.Users = []
          }
        } else {
          if (draft.room.Users === undefined) {
            draft.room.Users = []
          }
        }
      })
      setData(newObject)
    }

    fetchData()
  }, [id, hospiceId, BASE_URL])

  const room = data.room
  let program = { programName: '', programStartDateTime: undefined }

  let users = []
  if (type === 'upcoming') {
    if (data.nextEvent.Users) {
      users = data.nextEvent.Users
      program = data.nextEvent
    }
  } else if (type === 'ongoing') {
    if (data.room.Programs.length > 0) {
      if (data.room.Programs[0].Users) {
        users = data.room.Programs[0].Users
        program = data.room.Programs[0]
      }
    }
  }
  const userCount = users.length
  const chunkedData = chunk(users, 5)

  if (type === 'ongoing') {
    return (
      <div className="masterBackground">
        <div className="flex w-screen h-screen multi">
          <div className="w-11/12 mx-auto my-auto ">
            <Header dt={dt} />
            <div className="flex w-full h-auto">
              <Banner program={program} room={room} />
              <Attendees userCount={userCount} chunkedData={chunkedData} />
            </div>
          </div>
        </div>
      </div>
    )
  } else if (type === 'upcoming') {
    return (
      <div className="masterBackground">
        <div className="flex w-screen h-screen multi">
          <div className="w-11/12 mx-auto my-auto ">
            <Header dt={dt} />
            <div className="flex w-full h-auto">
              <UpcomingBanner program={program} room={room} />
              <div className="w-3/12 p-1 bg-white 2xl:p-6 bg-opacity-95 border-hca-grey rounded-xl h-7/8 attendBox ">
                <div
                  style={{
                    top: "45%",
                  }}
                  className="text-center justify-between my-auto text-xl relative"
                >
                  <p className="font-black font-avenir">
                    {userCount} participants
                  </p>
                  <p className="font-avenir font-book">
                    will attend this event
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (type === 'no-event') {
    return (
      <div className="masterBackground">
        <div className="flex w-screen h-screen no-event">
          <div
            style={{
              height: 'fit-content',
              borderBottomWidth: '20px',
              borderColor: '#16c787',
            }}
            className="w-8/12 pt-5 pb-10 mx-auto my-auto bg-white rounded-xl"
          >
            <NoEventHeader dt={dt} />
            <div
              style={{
                color: '#2c3445',
              }}
              className="px-4 text-center 2xl:px-12 my-auto">
              <div
                style={{
                  fontSize: '2.575rem',
                  lineHeight: '2.5rem',
                }}
                className="mb-3 font-black font-avenir"
              >
                No Upcoming Events Today
              </div>
              <div className="mb-16 text-3xl font-avenir font-book">
                {room.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (<div className="masterBackground"></div>)
  }
}

export default Detail
